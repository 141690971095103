import React from 'react'

// import PlaceIcon from '@mui/icons-material/Place'
import { Grid, Typography, Button, Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'

import Amplitude from 'lib/amplitude'

const FooterContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid #e0e0e0',
  backgroundColor: '#FFFFFF',
}))

const FooterGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    textAlign: 'center',
  },
}))

const FooterButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(1),
  },
}))

const Footer: React.FC = () => {
  const navigate = useNavigate()

  const handlePrivacyNavigate = (): void => {
    Amplitude.trackEvent('VIEW_PRIVACY_POLICY_PAGE', {})
    navigate('/privacy-policy')
  }

  const handleTermsNavigate = (): void => {
    Amplitude.trackEvent('VIEW_TERMS_OF_SERVICES_PAGE', {})
    navigate('/terms-of-service')
  }

  const handleReturnsNavigate = (): void => {
    Amplitude.trackEvent('VIEW_REFUND_AND_SERVICES_PAGE', {})
    navigate('/refund-and-cancellation')
  }

  const handleAboutUsNavigate = (): void => {
    navigate('/about-us')
  }
  const handleSocialNavigate = (url: string): void => {
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  return (
    <FooterContainer>
      <FooterGrid container>
        <Grid item xs={12} md={6} style={{ textAlign: 'left' }}>
          <p className="text-md font-bold mb-1 pl-2">ABOUT US</p>
          <FooterButton onClick={handleAboutUsNavigate}>Company</FooterButton>
          <FooterButton
            onClick={() =>
              handleSocialNavigate(
                'https://www.linkedin.com/company/haulistic-io/'
              )
            }
          >
            Linkedin
          </FooterButton>
          <FooterButton
            onClick={() =>
              handleSocialNavigate(
                'https://www.linkedin.com/company/haulistic-io/'
              )
            }
          >
            Twitter
          </FooterButton>
          <FooterButton
            onClick={() =>
              handleSocialNavigate('https://www.instagram.com/haulistic.io')
            }
          >
            Instagram
          </FooterButton>
          <FooterButton
            onClick={() =>
              handleSocialNavigate('https://www.youtube.com/@Haulistic')
            }
          >
            Youtube
          </FooterButton>
          {/* <p className="text-xs mt-2">
            <PlaceIcon fontSize="small" className="mr-2" />
            224, 3RD FLOOR, #80/3, VIJINAPUR VILLAGE, Doorvaninagar, <br />
            Bangalore North, Bangalore- 560016, Karnataka
          </p> */}
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            textAlign: 'left',
          }}
        >
          <Box>
            <p className="text-md font-bold mb-1 pl-2">POLICIES</p>

            <FooterButton onClick={handleTermsNavigate}>
              Terms of Service
            </FooterButton>
            <FooterButton onClick={handlePrivacyNavigate}>
              Privacy Policy
            </FooterButton>
            <FooterButton onClick={handleReturnsNavigate}>
              Returns and Refunds
            </FooterButton>
          </Box>
        </Grid>
      </FooterGrid>

      <Box sx={{ textAlign: 'center', mt: 2 }}>
        <Typography variant="body1">
          © 2024 Cartcrest E-Market Solutions Private Limited
        </Typography>
      </Box>
    </FooterContainer>
  )
}

export default Footer
