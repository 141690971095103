/* eslint-disable react/no-unescaped-entities */
import { Typography, Container, Box, Divider } from '@mui/material'

const TermsOfService = (): JSX.Element => {
  return (
    <Container maxWidth="md">
      <Box my={10}>
        <Typography variant="h4" gutterBottom>
          Haulistic Terms of Service
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Effective Date: 6 August 2024
        </Typography>
        <Divider />
        <Box my={3}>
          <Typography variant="h6" gutterBottom>
            1. Introduction
          </Typography>
          <Typography paragraph>
            Welcome to Haulistic, operated by CARTCREST E-MARKET SOLUTIONS
            PRIVATE LIMITED {`("we," "our," or "us").`} By using our platform,
            you agree to these Terms of Service {`("Terms").`} If you do not
            agree to these Terms, you may not use our services.
          </Typography>
        </Box>

        <Box my={3}>
          <Typography variant="h6" gutterBottom>
            2. Definitions
          </Typography>
          <Typography paragraph>
            <strong>&quot;Platform&quot;</strong> refers to the Haulistic
            website, dashboard application, and any associated services provided
            by CARTCREST E-MARKET SOLUTIONS PRIVATE LIMITED.
            <br />
            <strong>&quot;User&quot;</strong> refers to anyone who accesses or
            uses the Platform.
            <br />
            <strong>&quot;Seller&quot;</strong> refers to Users who register to
            sell products through their Instagram account using the Platform.
            <br />
            <strong>&quot;Customer&quot;</strong> refers to Users who purchase
            products listed by Sellers on the Platform.
            <br />
            <strong>&quot;Services&quot;</strong> refers to the tools and
            features provided by Haulistic, including product listing, inventory
            management, payment integration, delivery integration, and
            analytics.
          </Typography>
        </Box>

        <Box my={3}>
          <Typography variant="h6" gutterBottom>
            3. Account Registration
          </Typography>
          <Typography paragraph>
            Users must register for an account to access certain features of the
            Platform.
            <br />
            Sellers must provide accurate and up-to-date information about their
            business, products, and Instagram account.
            <br />
            Users are responsible for maintaining the confidentiality of their
            account credentials and are responsible for all activities that
            occur under their account.
          </Typography>
        </Box>

        <Box my={3}>
          <Typography variant="h6" gutterBottom>
            4. Use of the Platform
          </Typography>
          <Typography paragraph>
            Users agree to use the Platform in compliance with all applicable
            laws and regulations.
            <br />
            Sellers are responsible for listing accurate product descriptions,
            pricing, inventory levels, and ensuring the availability of
            products.
            <br />
            Sellers must ensure that their Instagram account complies with
            Instagram’s terms of service and community guidelines.
            <br />
            Sellers may use the Platform to customize their customer-facing
            application, including branding, pricing, and discount offers.
          </Typography>
        </Box>

        <Box my={3}>
          <Typography variant="h6" gutterBottom>
            5. Payments and Transactions
          </Typography>
          <Typography paragraph>
            Payment processing for transactions on the Platform is facilitated
            by third-party payment processors.
            <br />
            Sellers are responsible for ensuring that payments are correctly
            processed and that funds are received.
            <br />
            CARTCREST E-MARKET SOLUTIONS PRIVATE LIMITED is not responsible for
            any payment disputes between Sellers and Customers.
          </Typography>
        </Box>

        <Box my={3}>
          <Typography variant="h6" gutterBottom>
            6. Delivery and Fulfillment
          </Typography>
          <Typography paragraph>
            Sellers are responsible for integrating with delivery services and
            ensuring that products are shipped to Customers promptly and
            accurately.
            <br />
            Haulistic provides tools for delivery integration but is not
            responsible for the actual delivery of products.
          </Typography>
        </Box>

        <Box my={3}>
          <Typography variant="h6" gutterBottom>
            7. Intellectual Property
          </Typography>
          <Typography paragraph>
            CARTCREST E-MARKET SOLUTIONS PRIVATE LIMITED owns all rights to the
            Platform, including but not limited to software, design, content,
            and trademarks.
            <br />
            Users may not use any content or trademarks from the Platform
            without explicit permission.
          </Typography>
        </Box>

        <Box my={3}>
          <Typography variant="h6" gutterBottom>
            8. Limitation of Liability
          </Typography>
          <Typography paragraph>
            CARTCREST E-MARKET SOLUTIONS PRIVATE LIMITED is not liable for any
            damages or losses arising from the use of the Platform, including
            but not limited to loss of profits, data, or business opportunities.
            <br />
            The Platform is provided &quot;as is&quot; without any warranties,
            express or implied.
          </Typography>
        </Box>

        <Box my={3}>
          <Typography variant="h6" gutterBottom>
            9. Termination
          </Typography>
          <Typography paragraph>
            CARTCREST E-MARKET SOLUTIONS PRIVATE LIMITED reserves the right to
            terminate or suspend access to the Platform for any reason,
            including violation of these Terms.
            <br />
            Sellers may terminate their account at any time but are responsible
            for fulfilling any outstanding orders.
          </Typography>
        </Box>

        <Box my={3}>
          <Typography variant="h6" gutterBottom>
            10. Changes to Terms
          </Typography>
          <Typography paragraph>
            CARTCREST E-MARKET SOLUTIONS PRIVATE LIMITED may modify these Terms
            at any time. Users will be notified of any changes, and continued
            use of the Platform constitutes acceptance of the new Terms.
          </Typography>
        </Box>

        <Box my={3}>
          <Typography variant="h6" gutterBottom>
            11. Governing Law
          </Typography>
          <Typography paragraph>
            These Terms are governed by the laws of the Republic of India, and
            the courts of Bangalore, Karnataka, shall have exclusive
            jurisdiction.
          </Typography>
        </Box>
      </Box>
    </Container>
  )
}

const PrivacyPolicy = (): JSX.Element => {
  return (
    <Container maxWidth="md">
      <Box my={10}>
        <Typography variant="h4" gutterBottom>
          Haulistic Privacy Policy
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Effective Date: 6 August 2024
        </Typography>
        <Divider />
        <Box my={3}>
          <Typography variant="h6" gutterBottom>
            1. Introduction
          </Typography>
          <Typography paragraph>
            This Privacy Policy explains how CARTCREST E-MARKET SOLUTIONS
            PRIVATE LIMITED {`("we," "our," or "us")`} collects, uses, and
            protects your personal information through Haulistic. By using our
            Platform, you agree to the practices described in this policy.
          </Typography>
        </Box>

        <Box my={3}>
          <Typography variant="h6" gutterBottom>
            2. Information We Collect
          </Typography>
          <Typography paragraph>
            <strong>Personal Information:</strong> We collect information such
            as your name, email address, phone number, and payment information
            when you register, sell products, or make purchases on the Platform.
            <br />
            <strong>Business Information:</strong> For Sellers, we collect
            information about your Instagram account, product listings,
            inventory, and transaction history.
            <br />
            <strong>Usage Data:</strong> We collect data on how you use the
            Platform, including IP addresses, browser types, pages visited, and
            actions taken within the dashboard.
          </Typography>
        </Box>

        <Box my={3}>
          <Typography variant="h6" gutterBottom>
            3. How We Use Your Information
          </Typography>
          <Typography paragraph>
            <strong>To Provide Services:</strong> We use your personal and
            business information to facilitate transactions, manage product
            listings, integrate payments, and support delivery services.
            <br />
            <strong>To Improve the Platform:</strong> We analyze usage data to
            enhance the functionality, security, and user experience of the
            Platform.
            <br />
            <strong>Marketing:</strong> With your consent, we may use your
            information to send promotional materials and offers related to
            Haulistic’s services.
          </Typography>
        </Box>

        <Box my={3}>
          <Typography variant="h6" gutterBottom>
            4. Sharing Your Information
          </Typography>
          <Typography paragraph>
            <strong>Third-Party Service Providers:</strong> We may share your
            information with third-party providers who assist with payment
            processing, data analysis, and delivery services.
            <br />
            <strong>Legal Requirements:</strong> We may disclose your
            information to comply with legal obligations, court orders, or to
            protect our rights and safety.
          </Typography>
        </Box>

        <Box my={3}>
          <Typography variant="h6" gutterBottom>
            5. Data Security
          </Typography>
          <Typography paragraph>
            We implement appropriate technical and organizational measures to
            protect your personal and business information from unauthorized
            access, use, or disclosure.
          </Typography>
        </Box>

        <Box my={3}>
          <Typography variant="h6" gutterBottom>
            6. Data Retention
          </Typography>
          <Typography paragraph>
            We retain your personal and business information for as long as
            necessary to fulfill the purposes outlined in this Privacy Policy,
            unless a longer retention period is required by law.
          </Typography>
        </Box>

        <Box my={3}>
          <Typography variant="h6" gutterBottom>
            7. Your Rights
          </Typography>
          <Typography paragraph>
            <strong>Access and Correction:</strong> You can access and update
            your personal and business information by logging into your account.
            <br />
            <strong>Deletion:</strong> You can request the deletion of your
            personal and business information, subject to certain legal and
            contractual obligations.
            <br />
            <strong>Opt-Out:</strong> You can opt-out of receiving marketing
            communications by following the instructions in those
            communications.
          </Typography>
        </Box>

        <Box my={3}>
          <Typography variant="h6" gutterBottom>
            8. Children&apos;s Privacy
          </Typography>
          <Typography paragraph>
            Haulistic does not knowingly collect personal information from
            children under 13. If we learn that we have collected such
            information, we will take steps to delete it.
          </Typography>
        </Box>

        <Box my={3}>
          <Typography variant="h6" gutterBottom>
            9. Changes to This Privacy Policy
          </Typography>
          <Typography paragraph>
            We may update this Privacy Policy from time to time. We will notify
            you of any changes by posting the new policy on the Platform.
          </Typography>
        </Box>

        <Box my={3}>
          <Typography variant="h6" gutterBottom>
            10. Contact Us
          </Typography>
          <Typography paragraph>
            If you have any questions or concerns about this Privacy Policy,
            please contact us at{' '}
            <a href="admin@haulistic.io">admin@haulistic.io</a>.
          </Typography>
        </Box>
      </Box>
    </Container>
  )
}

const RefundAndCancellation = (): JSX.Element => {
  return (
    <Container maxWidth="md">
      <Box my={10}>
        <Typography variant="h4" gutterBottom>
          Refund and Cancellation Policy
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Effective Date: 6 August 2024
        </Typography>
        <Divider />

        <Box my={3}>
          <Typography variant="h6" gutterBottom>
            Our Commitment to You
          </Typography>
          <Typography paragraph>
            At Haulistic, we value your business and are dedicated to providing
            a smooth and enjoyable experience. This policy is designed to be
            clear and transparent, so you always know what to expect. If you
            have any questions or concerns, we’re here to help at every step.
            Thank you for choosing Haulistic!
          </Typography>
        </Box>

        <Box my={3}>
          <Typography variant="h6" gutterBottom>
            1. Introduction
          </Typography>
          <Typography paragraph>
            This Refund and Cancellation Policy outlines the terms under which
            refunds and cancellations will be processed for Haulistic’s monthly
            subscription services. By subscribing to Haulistic, you agree to the
            terms outlined in this policy.
          </Typography>
        </Box>

        <Box my={3}>
          <Typography variant="h6" gutterBottom>
            2. Subscription Cancellation
          </Typography>

          <Typography variant="body1" paragraph>
            <strong>2.1. Monthly Subscriptions</strong>
          </Typography>
          <Typography paragraph>
            - You may cancel your monthly subscription at any time by contacting
            Haulistic’s support team at support@haulistic.io.
            <br />- Cancellations will take effect at the end of your current
            billing cycle. You will retain access to Haulistic's services until
            the end of the month in which you cancel.
          </Typography>
        </Box>

        <Box my={3}>
          <Typography variant="h6" gutterBottom>
            3. Refund Policy
          </Typography>

          <Typography variant="body1" paragraph>
            <strong>3.1. Refund Eligibility</strong>
          </Typography>
          <Typography paragraph>
            - Refunds are available for monthly subscriptions canceled on or
            before the 20th of the current month.
            <br />- If you cancel after the 20th of the month, no refund will be
            provided for the current month, but your subscription will not renew
            in the next billing cycle.
          </Typography>

          <Typography variant="body1" paragraph>
            <strong>3.2. Refund Process</strong>
          </Typography>
          <Typography paragraph>
            - To request a refund, please contact Haulistic support at
            support@haulistic.io.
            <br />- Refunds for eligible cancellations will be processed within
            7-10 business days and credited to the original payment method.
          </Typography>
        </Box>

        <Box my={3}>
          <Typography variant="h6" gutterBottom>
            4. Service Downtime or Disruption
          </Typography>
          <Typography paragraph>
            Haulistic is committed to providing reliable and high-quality
            service to our users. We understand the importance of uninterrupted
            access and will strive to maintain high availability.
          </Typography>

          <Typography variant="body1" paragraph>
            <strong> 4.1. Minor Service Disruptions</strong>
          </Typography>
          <Typography paragraph>
            - In the event of minor service disruptions, we will offer refunds
            on a prorated basis to compensate for the affected period. Please
            reach out to our support team at support@haulistic.io if you
            experience a disruption that you feel qualifies.
          </Typography>

          <Typography variant="body1" paragraph>
            <strong> 4.2. Severe Disruptions</strong>
          </Typography>
          <Typography paragraph>
            - If there is a severe disruption lasting more than three
            consecutive days, we will proactively refund the entire month’s
            subscription fee for impacted users, as we recognize the
            inconvenience this may cause.
          </Typography>

          <Typography paragraph>
            We value your trust and are here to ensure you receive the best
            experience possible. Haulistic is not responsible for disruptions
            caused by third-party service providers, though we will work closely
            with them to resolve any issues swiftly.
          </Typography>
        </Box>

        <Box my={3}>
          <Typography variant="h6" gutterBottom>
            5. Modifications to Services or Pricing
          </Typography>
          <Typography paragraph>
            At Haulistic, we’re always working to improve our services and
            features to better serve you. Occasionally, this may mean adjusting
            our offerings or pricing to keep up with new developments and ensure
            the highest quality of service.
          </Typography>

          <Typography variant="body1" paragraph>
            <strong> 5.1. Service and Feature Updates</strong>
          </Typography>
          <Typography paragraph>
            - We may introduce new features, make updates, or modify existing
            services. These changes will be aimed at enhancing your experience
            and helping your business grow.
          </Typography>

          <Typography variant="body1" paragraph>
            <strong> 5.2. Pricing Adjustments</strong>
          </Typography>
          <Typography paragraph>
            - Should there be any changes to our pricing, we will provide you
            with at least two months' notice before the new rates take effect.
            This gives you ample time to review and adjust your subscription if
            needed.
          </Typography>

          <Typography paragraph>
            We’re committed to transparency and keeping you informed, so you
            always know what to expect from Haulistic.
          </Typography>
        </Box>

        <Box my={3}>
          <Typography variant="h6" gutterBottom>
            6. Contact Information
          </Typography>
          <Typography paragraph>
            For questions, cancellations, or refund requests, please contact us
            at support@haulistic.io.
          </Typography>
        </Box>

        <Box my={3}>
          <Typography variant="h6" gutterBottom>
            7. Frequently Asked Questions (FAQ)
          </Typography>
          <Typography paragraph>
            <strong>Q1: How do I cancel my subscription?</strong>
            <br />
            A: Simply contact our support team at support@haulistic.io, and
            we’ll guide you through the cancellation process. You’ll continue to
            have access to your subscription until the end of the current
            billing cycle.
          </Typography>
          <Typography paragraph>
            <strong>
              Q2: Will I get a refund if I cancel my subscription in the middle
              of the month?
            </strong>
            <br />
            A: Yes, if you cancel on or before the 20th of the month, you’re
            eligible for a refund for the current month. Cancellations after the
            20th will take effect the following month.
          </Typography>
          <Typography paragraph>
            <strong>Q3: What if there’s a minor disruption in service?</strong>
            <br />
            A: If you experience minor service disruptions, please reach out to
            us at support@haulistic.io. We’ll assess the impact and offer
            prorated refunds to cover the affected period.
          </Typography>
          <Typography paragraph>
            <strong>
              Q4: What happens if there’s a severe service outage?
            </strong>
            <br />
            A: If there’s a severe outage lasting more than three days, we’ll
            automatically refund your subscription for the entire month.
          </Typography>
          <Typography paragraph>
            <strong>
              Q5: How will I know if there are changes in pricing or services?
            </strong>
            <br />
            A: We’ll notify you at least two months in advance of any pricing
            adjustments, so you’ll have plenty of time to review and make any
            necessary changes to your subscription.
          </Typography>
          <Typography paragraph>
            <strong>Q6: Who do I contact if I have more questions?</strong>
            <br />
            A: For any additional questions or support, feel free to reach out
            to us at support@haulistic.io. We’re here to help!
          </Typography>
        </Box>
      </Box>
    </Container>
  )
}

export { TermsOfService, PrivacyPolicy, RefundAndCancellation }
