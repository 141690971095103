import React from 'react'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

interface AccordionHeaderProps {
  productId: number
  productName: string
  lastUpdated: string
  lastUpdatedBy: string
  expanded: boolean
}

const StockAccordionHeader: React.FC<AccordionHeaderProps> = ({
  productId,
  productName,
  lastUpdated,
  lastUpdatedBy,
  expanded,
}) => {
  const navigate = useNavigate()

  const handleNavigateToProduct = () => {
    navigate(`/dashboard/products/${productId}`)
  }

  return (
    <>
      <ExpandMoreIcon
        sx={{
          transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
          transition: 'transform 0.3s ease-in-out',
          marginRight: 2,
        }}
      />
      <Box className="flex-1 ">
        <Typography variant="body2" className=" text-gray-500">
          Product
        </Typography>
        <Typography
          className="inline-block hover:underline"
          onClick={handleNavigateToProduct}
        >
          {productName}
        </Typography>
      </Box>
      <Box sx={{ flex: 1, textAlign: 'left' }}>
        <Typography variant="body2" className=" text-gray-500">
          Last Updated At
        </Typography>
        <Typography>{lastUpdated}</Typography>
      </Box>
      <Box sx={{ flex: 0.5, textAlign: 'left', marginLeft: 2 }}>
        <Typography variant="body2" className=" text-gray-500">
          Last Updated By
        </Typography>
        <Typography>{lastUpdatedBy}</Typography>
      </Box>
    </>
  )
}

export default StockAccordionHeader
