import { FC, useEffect, useState } from 'react'

import { Box, Button } from '@mui/material'

import Card from 'components/card'
import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import Amplitude from 'lib/amplitude'
import SubTabHeader from 'pages/dashboard/components/SubTabHeader'
import { updateProduct } from 'services/products'
import { Product } from 'services/products/index.types'

import VariantsTable from './VariantsTable'

type VariantType = {
  id?: number
  type: string
  name: string
  price: number | string
  quantity: number | string
  cost: number | string
}

interface VariantDetailsProps {
  variants?: VariantType[]
  productId: number
  onUpdate: (data?: Product) => void
}

const VariantsDetails: FC<VariantDetailsProps> = ({
  variants = [],
  productId,
  onUpdate,
}) => {
  const [edit, setEdit] = useState(false)
  const [rows, setRows] = useState<VariantType[]>([])
  const [error, setError] = useState<{ message: string } | null>(null)
  const { organizationId } = useAuth()
  const { showSnackbar } = useSnackbar()
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    setRows(variants)
  }, [variants])

  const onRowsChange = (arr: VariantType[]): void => {
    setRows(arr)
  }

  const handleSave = async (): Promise<void> => {
    if (rows.length === 0) {
      setError({ message: 'Product should have at least one variant.' })
      return
    }

    if (organizationId) {
      Amplitude.trackEvent('PRODUCT_VARIANT_SAVE_CLICKED', {
        productId: productId,
        orgId: organizationId,
      })
    }

    setIsSubmitting(true)
    const res = await updateProduct({
      productId,
      dataUpdate: { variants: rows },
    })

    if (res.status === 'successful') {
      showSnackbar('Variants updated successfully.', 'success')
      onUpdate(res.data)
    }
    setIsSubmitting(false)

    setError(null)
    setEdit(!edit)
  }

  const handleEdit = (): void => {
    if (organizationId) {
      Amplitude.trackEvent('PRODUCT_VARIANT_EDIT_CLICKED', {
        productId: productId,
        orgId: organizationId,
      })
    }
    setEdit(true)
  }

  const handleCancel = (): void => {
    if (organizationId) {
      Amplitude.trackEvent('PRODUCT_VARIANT_CANCEL_CLICKED', {
        productId: productId,
        orgId: organizationId,
      })
    }
    setRows(variants)
    setEdit(false)
  }

  return (
    <div>
      <div className="mb-5">
        <div className=" mx-5">
          <SubTabHeader
            title="Variants & Stock"
            buttonText={edit ? undefined : 'Edit'}
            onClick={handleEdit}
          />
        </div>
        <Card>
          {error && (
            <p className=" text-xs text-red-500 mb-2">{error.message}</p>
          )}
          <Box>
            <VariantsTable
              edit={edit}
              rowData={rows}
              onRowAdd={onRowsChange}
              onRowRemove={onRowsChange}
            />
          </Box>
          {edit && (
            <div className="mt-4">
              <Button
                variant="outlined"
                size="small"
                sx={{ textTransform: 'none', mr: 2 }}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="small"
                sx={{ textTransform: 'none' }}
                onClick={handleSave}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Saving' : 'Save'}
              </Button>
            </div>
          )}
        </Card>
      </div>
    </div>
  )
}

export default VariantsDetails
