import { useState } from 'react'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, IconButton } from '@mui/material'
import Button from '@mui/material/Button'
import { Form, Formik } from 'formik'
import { useNavigate } from 'react-router-dom'

import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import Amplitude from 'lib/amplitude'
import TabTitle from 'pages/dashboard/components/TabTitle'
import { createProduct } from 'services/products'
import { CreateProductParams } from 'services/products/index.types'
import { VariantOption } from 'types/product'

import ProductDetails from '../components/Details'
import ProductFiles from '../components/Files'
import CreateProductPreview from '../components/FormPreview'
import NotesAndTags from '../components/NotesAndTags'
import ProductOffers from '../components/Offers'
import ProductPricing from '../components/Pricing'
import ProductStatus from '../components/Status'
import { validationSchema } from '../schema/CreateProductSchema'

interface AddProductPageProps {
  productId?: number
}
export interface Variants {
  name: string
  type: string
  price: string
  quantity: string
  costPerUnit: string
  discountedPrice: string
  discountPercentage: string
  offerValidTill: [Date | null, Date | null]
}

export interface ProductDetailFormValues {
  name: string
  category: number[]
  description: string
  price: string
  quantity: string
  costPerUnit: string
  discountedPrice: string
  discountPercentage: string
  offerValidTill: [Date | null, Date | null]
  variants: Variants[]
  seoTitle?: string
  seoDescription?: string
  seoLink?: string
  notes: string
  tagIds: number[]
  isActive: boolean
  isListed: boolean
}

const AddProductPage: React.FC<AddProductPageProps> = ({ productId }) => {
  const navigate = useNavigate()
  const [variantAdd, setVariantAdd] = useState(false)
  const [addFiles, setAddFiles] = useState<string[]>()
  const { organizationId } = useAuth()
  const { showSnackbar } = useSnackbar()

  const initialValues: ProductDetailFormValues = {
    name: '',
    category: [],
    description: '',
    price: '',
    discountedPrice: '',
    quantity: '',
    costPerUnit: '',
    discountPercentage: '',
    offerValidTill: [null, null],
    variants: [],
    seoTitle: '',
    seoDescription: '',
    seoLink: '',
    notes: '',
    tagIds: [],
    isActive: false,
    isListed: false,
  }

  const variantAddHandler = (val: boolean): void => {
    setVariantAdd(val)
  }

  const fileAddHanlder = (files: string[]): void => {
    setAddFiles(files)
  }

  const submitHandler = async (
    values: ProductDetailFormValues
  ): Promise<void> => {
    const {
      name,
      description,
      price,
      discountedPrice,
      variants,
      seoTitle,
      seoDescription,
      seoLink,
      category,
      notes,
      tagIds,
      discountPercentage,
      costPerUnit,
      quantity,
      offerValidTill,
      isActive,
      isListed,
    } = values

    let media: string[] = []

    if (addFiles) {
      media = addFiles.map((f) => `${process.env.REACT_APP_FILE_URL}/${f}`)
    }

    let data: Partial<CreateProductParams> = {
      name,
      description: description,
      seoTitle,
      seoLink: `https://haulistic.io/${seoLink}`,
      seoDescription: seoDescription,
      media,
      price: Number(price),
      discountedPrice: Number(discountedPrice),
      categoryIds: category,
      notes: notes !== '' ? notes : undefined,
      tagIds: tagIds.length > 0 ? tagIds : undefined,
      isActive,
      isListed,
    }

    let variantsData: VariantOption[] = []

    if (variants && variants.length > 0) {
      variantsData = variants.map((v) => ({
        type: v.type,
        name: v.name,
        price: Number(v.price),
        discountedPrice: Number(v.discountedPrice),
        quantity: +v.quantity,
        cost: +v.costPerUnit,
        discountPercentage: +v.discountPercentage,
        offerValidity: v.offerValidTill.includes(null)
          ? undefined
          : v.offerValidTill,
      }))
      data.variants = variantsData
    } else {
      data = {
        ...data,
        discountPercentage: +discountPercentage,
        quantity: +quantity,
        cost: +costPerUnit,
        offerValidity:
          offerValidTill && offerValidTill.includes(null)
            ? undefined
            : offerValidTill,
      }
    }
    const res = organizationId
      ? await createProduct({
          ...data,
          organizationId: organizationId,
        })
      : { status: 'failed' }

    if (organizationId) {
      Amplitude.trackEvent('PRODUCT_ADD_PRODUCT_CLICKED', {
        orgId: organizationId,
      })
    }

    if (res.status === 'successful') {
      showSnackbar('Product Created.', 'success')
      navigate('/dashboard/products/all', { replace: true })
    } else if (res.status === 'failed') {
      res.message
        ? showSnackbar(res.message, 'error')
        : showSnackbar('Please try again later.', 'error')
    }
  }

  const handleBackNavigation = (): void => {
    navigate(-1)
  }

  return (
    <>
      <Box display="flex" alignItems={'center'} marginBottom={2}>
        <IconButton
          sx={{ color: 'text.primary' }}
          onClick={handleBackNavigation}
        >
          <ArrowBackIcon />
        </IconButton>
        <TabTitle title="New Product" />
      </Box>
      <Box className="mb-5">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => submitHandler(values)}
        >
          {(formik) => (
            <Form>
              <Box display={'flex'} flexDirection={{ xs: 'column', md: 'row' }}>
                <Box flex={2}>
                  <ProductDetails productId={productId} />
                  <ProductFiles
                    productId={productId}
                    onFilesAdd={fileAddHanlder}
                  />
                  <ProductPricing
                    productId={productId}
                    variantsAdded={variantAdd}
                    onVariantAdd={variantAddHandler}
                  />
                  <ProductOffers variantAdded={variantAdd} />
                  {/* <ProductSeo productId={productId} /> */}
                  <NotesAndTags />
                  <ProductStatus />
                  <Box
                    display={{ xs: 'none', md: 'flex' }}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    <Button type="submit" variant="contained">
                      Add Product
                    </Button>
                  </Box>
                </Box>
                {formik.values.name.length > 0 && (
                  <CreateProductPreview files={addFiles} />
                )}
                <Box
                  display={{ xs: 'flex', md: 'none' }}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Button type="submit" variant="contained">
                    Add Product
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  )
}

export default AddProductPage
