import React from 'react'

import {
  Typography,
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  IconButton,
} from '@mui/material'
import { FaLinkedin, FaTwitter } from 'react-icons/fa6'

import theme from 'styles/muiTheme'

export const AboutUs: React.FC = () => {
  return (
    <Box className="mt-20">
      {/* Header Section */}
      <Box className="text-center mb-4">
        <img
          src={
            process.env.REACT_APP_ASSETS_URL +
            '/images/logos/logoWithBrandName.png'
          }
          alt="Haulistic Logo"
          className="mx-auto mb-2 w-24"
        />
        <Typography variant="h5" className="font-bold text-gray-800">
          A HOLISTIC TOOL FOR YOUR ONLINE BUSINESS
        </Typography>
      </Box>
      <Container maxWidth="lg">
        <Card
          variant="outlined"
          className="mx-8 p-4 my-8"
          sx={{
            backgroundColor: theme.palette.secondary.main,
            borderColor: theme.palette.primary.main,
          }}
        >
          <Typography variant="body1">
            At Haulistic, we believe in the potential of small and medium-sized
            businesses to make a big impact. With the surge of sellers on
            Instagram and the growing demand for their products, we saw a need
            for a platform tailored specifically to support them. Haulistic was
            built with this in mind—to provide all the tools and features
            sellers need to succeed in the dynamic world of social commerce.
          </Typography>
        </Card>

        {/* Content Sections */}
        <Box className="px-8">
          {/* Our Story */}
          <Box
            component="p"
            className="mb-4 font-bold text-2xl"
            sx={{ color: theme.palette.primary.main }}
          >
            Our Story
          </Box>
          <Card variant="outlined" className="my-4">
            <CardContent>
              <Typography variant="body1" className="text-gray-700">
                The idea for Haulistic was born after witnessing the rapid
                increase in sellers on Instagram and the immense demand for
                their unique offerings. Recognizing the challenges faced by
                small and medium-sized businesses in managing their online
                presence and scaling effectively, we set out to build a product
                that caters to their needs.
                <br />
                <br />
                Whether you’re an Instagram seller looking to elevate your brand
                or a small business ready to expand online, Haulistic provides
                the right tools for you.
              </Typography>
            </CardContent>
          </Card>

          {/* Our Mission */}
          <Box
            component="p"
            className="mb-4 font-bold text-2xl"
            sx={{ color: theme.palette.primary.main }}
          >
            Our Mission
          </Box>
          <Card variant="outlined" className="my-4">
            <CardContent>
              <Typography variant="body1" className="text-gray-700">
                Our mission is to build an all-in-one solution that empowers
                small to medium-sized businesses to thrive. We focus on
                developing features specifically designed for Instagram sellers
                while ensuring that businesses from all industries can benefit
                from our platform’s ease of use and powerful functionalities.
              </Typography>
            </CardContent>
          </Card>

          {/* Our Leadership */}
          <Box
            component="p"
            className="mb-4 font-bold text-2xl"
            sx={{ color: theme.palette.primary.main }}
          >
            Our Leadership
          </Box>
          <Card variant="outlined" className="my-4">
            <CardContent>
              <Typography variant="body1" className="text-gray-700">
                We are a team of four passionate individuals based in Bangalore,
                India, dedicated to helping sellers thrive. Our focus is always
                on understanding and anticipating the needs of our users,
                allowing us to create a platform that not only meets their
                expectations but grows alongside their businesses.
              </Typography>
              <Container className="w-2/3 mt-8">
                <Box display={'flex'} justifyContent={'space-around'}>
                  <Box>
                    <Card sx={{ maxWidth: 250 }}>
                      <CardMedia
                        component="img"
                        height="194"
                        image={
                          process.env.REACT_APP_ASSETS_URL +
                          '/images/founder-pics/jeevan.jpeg'
                        }
                        alt="Jeevan Thomas Koshy"
                      />
                    </Card>
                    <p className="text-center font-bold mt-2">
                      Jeevan Thomas Koshy
                    </p>
                    <Box display={'flex'} justifyContent={'center'}>
                      <IconButton
                        component="a"
                        href="https://x.com/jeevan_tk"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaTwitter color="black" />
                      </IconButton>
                      <IconButton
                        component="a"
                        href="https://www.linkedin.com/in/jeevan-thomas-koshy-085844a8"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaLinkedin color="black" />
                      </IconButton>
                    </Box>
                  </Box>

                  <Box>
                    <Card sx={{ maxWidth: 250 }}>
                      <CardMedia
                        component="img"
                        height="194"
                        image={
                          process.env.REACT_APP_ASSETS_URL +
                          '/images/founder-pics/maria.jpeg'
                        }
                        alt="Maria Zacharia"
                      />
                    </Card>
                    <p className="text-center font-bold mt-2">Maria Zacharia</p>
                    <Box display={'flex'} justifyContent={'center'}>
                      <IconButton
                        component="a"
                        href="https://x.com/zacharia_maria"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaTwitter color="black" />
                      </IconButton>
                      <IconButton
                        component="a"
                        href="https://www.linkedin.com/in/maria-zacharia-609804180"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaLinkedin color="black" />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              </Container>
            </CardContent>
          </Card>
        </Box>
      </Container>
    </Box>
  )
}
