import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, IconButton } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import TabTitle from 'pages/dashboard/components/TabTitle'

import CustomerEdit from '../components/CustomerEdit'

const AddCustomerPage = (): JSX.Element => {
  const navigate = useNavigate()
  const handleBackNavigation = (): void => {
    navigate(-1)
  }
  return (
    <>
      <Box display="flex" alignItems={'center'} mb={2}>
        <IconButton
          sx={{ color: 'text.primary' }}
          onClick={handleBackNavigation}
        >
          <ArrowBackIcon />
        </IconButton>
        <TabTitle title="New Customer" />
      </Box>
      <CustomerEdit />
    </>
  )
}

export default AddCustomerPage
