import { useEffect, useState } from 'react'

import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'

import { useSnackbar } from 'hooks/useSnackbar'
import ConfirmationDialog from 'pages/dashboard/components/ConfrimationDialog'
import TabTitle from 'pages/dashboard/components/TabTitle'
import { deleteProduct, getProduct } from 'services/products'
import { Product } from 'services/products/index.types'

import DetailPreview from '../components/DetailPreview'
import FilesDetails from '../components/FilesDetails'
import NotesDetails from '../components/NotesDetails'
import OffersDetails from '../components/OffersDetails'
import PricingDetails from '../components/PricingDetails'
import BasicDetails from '../components/ProductDetails'
// import SeoDetails from '../components/SeoDetails'
import TagsDetails from '../components/TagsDetails'
import VariantsDetails from '../components/VariantsDetails'
import VariantsOfferDetails from '../components/VariantsOffersDetails'

const ProductDetailsPage = (): JSX.Element => {
  const [product, setProduct] = useState<Product>()
  const { productId } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const naviagte = useNavigate()
  const [open, setOpen] = useState(false)
  const [dataUpdate, setDataUpdate] = useState(false)
  const [deleteDialog, setDeleteDialog] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openAction = Boolean(anchorEl)
  const { showSnackbar } = useSnackbar()

  const handleClickOpen = (): void => {
    setOpen(true)
  }

  const handleClose = (): void => {
    setOpen(false)
  }

  useEffect(() => {
    async function get(): Promise<void> {
      setIsLoading(true)
      const result = productId
        ? await getProduct({ id: Number(productId) })
        : { status: 'failed' }
      if (result.status === 'successful') setProduct(result.data)
      setIsLoading(false)
    }
    get()
  }, [productId])

  useEffect(() => {
    async function get(): Promise<void> {
      setIsLoading(true)
      const result = productId
        ? await getProduct({ id: Number(productId) })
        : { status: 'failed' }
      if (result.status === 'successful') {
        setProduct(result.data)
      }
      setIsLoading(false)
      setDataUpdate(false)
    }
    if (dataUpdate) get()
  }, [dataUpdate, productId])

  const handleUpdate = (data?: Product): void => {
    if (data) {
      setProduct(data)
    } else {
      setDataUpdate(true)
    }
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleActionClose = (): void => {
    setAnchorEl(null)
  }

  const handleDeleteDialog = (): void => {
    setDeleteDialog(true)
  }

  const handleDeleteProduct = async (): Promise<void> => {
    if (product) {
      const result = await deleteProduct(product.id)
      if (result.status === 'successful') {
        showSnackbar('Product Deleted.', 'success')
        naviagte(-1)
      } else if (result.status === 'failed') {
        showSnackbar(result.message ?? 'Please try again later.', 'error')
      }
    }
    setDeleteDialog(false)
    handleClose()
  }

  if (isLoading) return <h1>Loading...</h1>

  if (!product) return <h1>No Product found.</h1>

  const variants = product.variants.map((variant, index) => ({
    id: variant.id,
    type: variant.type,
    name: variant.name,
    price: variant.price,
    discountedPrice: variant.discountedPrice,
    quantity: product.stock[index].quantity,
    cost: product.stock[index].cost,
  }))

  const offerVariants = product.variants.map((variant) => ({
    id: variant.id,
    name: variant.name,
    price: variant.price,
    discountedPrice: variant.discountedPrice,
    discountPercentage: variant.discountPercentage,
    offerValidity: variant.offerValidity,
  }))

  const previewProductData = {
    name: product.name,
    price: product.price,
    discountedPrice: product.discountedPrice,
    description: product.description,
    variants: variants,
  }
  const mediaFiles = product.media.map((m) => m.split('/')[3])

  return (
    <Box className="mb-5">
      <div className=" flex items-center justify-between mr-5">
        <div className="flex items-center ml-2">
          <IconButton
            sx={{ color: 'text.primary' }}
            onClick={() => naviagte(-1)}
          >
            <ArrowBackOutlinedIcon />
          </IconButton>
          <TabTitle title={product.name} />
        </div>
        <div>
          <Button
            variant="contained"
            size="small"
            sx={{
              paddingInline: '1rem',
              textTransform: 'none',
            }}
            id="basic-button"
            aria-controls={openAction ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openAction ? 'true' : undefined}
            onClick={handleClick}
          >
            Actions
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openAction}
            onClose={handleActionClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={handleClickOpen}>Show Product Preview</MenuItem>
            <MenuItem onClick={handleDeleteDialog}>Delete Product</MenuItem>
          </Menu>
        </div>
      </div>
      <BasicDetails product={product} />

      {variants.length === 0 && (
        <PricingDetails
          price={product.price}
          quantity={product.stock[0].quantity}
          costPerUnit={product.stock[0].cost}
          stockId={product.stock[0].id}
          productId={product.id}
          onUpdate={handleUpdate}
        />
      )}

      <FilesDetails media={product.media} productId={product.id} />
      {variants.length === 0 && (
        <OffersDetails
          price={product.price}
          discountedPrice={product.discountedPrice}
          discountPercentage={product.discountPercentage}
          offerValidity={product.offerValidity}
          productId={product.id}
          onUpdate={handleUpdate}
        />
      )}
      {variants.length > 0 && (
        <VariantsDetails
          variants={variants}
          productId={product.id}
          onUpdate={handleUpdate}
        />
      )}

      {variants.length > 0 && (
        <VariantsOfferDetails
          variants={offerVariants}
          productId={product.id}
          onUpdate={handleUpdate}
        />
      )}

      {/* <SeoDetails
        seoValues={{
          seoTitle: product.seoTitle,
          seoDescription: product.seoDescription,
          seoLink: product.seoLink,
        }}
        productId={product.id}
        onUpdate={handleUpdate}
      /> */}
      <NotesDetails productId={product.id} notesData={product.notes} />
      <TagsDetails
        productId={product.id}
        tagIds={product.tagIds}
        onUpdate={handleUpdate}
      />
      <div>
        <ConfirmationDialog
          open={deleteDialog}
          title="Delete Product"
          content="This action will permanently delete the product. Are you sure you want to proceed?"
          onClose={() => setDeleteDialog(false)}
          onConfirm={handleDeleteProduct}
        />
      </div>
      <div>
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
          <DialogTitle>Preview</DialogTitle>
          <DialogContent>
            <DetailPreview product={previewProductData} files={mediaFiles} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
    </Box>
  )
}

export default ProductDetailsPage
