import React, { useEffect, useState } from 'react'

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Paper,
} from '@mui/material'

import TabTitle from 'pages/dashboard/components/TabTitle'
import { getAllStock } from 'services/stock'
import theme from 'styles/muiTheme'
import { StockProductData } from 'types/stock'
import { dateTimeFormat } from 'utils/dateFormat'

import SearchFilter from './SearchFilter'
import StockAccordionHeader from './StockAccordionHeader'
import StockDetailsTable from './StockDetailsTable'

interface StockListProps {
  orgId: number
}

interface Variant {
  id: number
  name: string
  cost: string
  quantity: number
  sold: number
  stockId: number
}

interface StockProduct {
  id: number
  name: string
  lastUpdated: string
  lastUpdatedBy: string
  variants: Variant[]
}

const StockList: React.FC<StockListProps> = ({ orgId }): JSX.Element => {
  const [expanded, setExpanded] = useState<string | false>(false)
  const [list, setList] = useState<StockProductData[]>([])

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  useEffect(() => {
    async function fetchData(): Promise<void> {
      const queryOptions = {
        orgId,
      }
      const result = await getAllStock(queryOptions)
      if (result.status === 'successful' && result.data) {
        setList(result.data)
      }
    }
    fetchData()
  }, [orgId])

  const stockProducts: StockProduct[] = list
    .filter((product) => product.stock && product.stock.length > 0)
    .map((product) => ({
      id: product.id,
      name: product.name,
      lastUpdated: dateTimeFormat(product.stock[0].updatedAt),
      lastUpdatedBy: `${product.stock[0].updatedByUser.firstName} ${product.stock[0].updatedByUser.lastName || ''}`,
      variants: product.stock.map((stockItem) => ({
        id: stockItem.variantId,
        name: stockItem.variant ? stockItem.variant.name : '',
        cost: stockItem.cost ? stockItem.cost.toString() : 'N/A',
        quantity: stockItem.quantity,
        sold: stockItem.quantitySold,
        stockId: stockItem.id,
      })),
    }))

  return (
    <Box>
      <div className="mb-4 mx-5">
        <TabTitle title="Stock" />
      </div>
      <SearchFilter />
      <Paper
        className="mx-5 mt-4"
        sx={{
          backgroundColor: 'secondary.main',
          boxShadow: '0 0 8px 2px rgba(0, 0, 0, 0.1)',
          borderRadius: '5px',
        }}
      >
        {stockProducts.map((product) => (
          <Accordion
            key={product.id}
            expanded={expanded === `panel${product.id}`}
            onChange={handleAccordionChange(`panel${product.id}`)}
          >
            <AccordionSummary
              aria-controls={`panel${product.id}-content`}
              id={`panel${product.id}-header`}
              sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: theme.palette.secondary.main,
              }}
            >
              <StockAccordionHeader
                productId={product.id}
                productName={product.name}
                lastUpdated={product.lastUpdated}
                lastUpdatedBy={product.lastUpdatedBy}
                expanded={expanded === `panel${product.id}`}
              />
            </AccordionSummary>
            <AccordionDetails
              sx={{
                backgroundColor: theme.palette.secondary.main,
              }}
            >
              <StockDetailsTable variants={product.variants} />
            </AccordionDetails>
          </Accordion>
        ))}
      </Paper>
    </Box>
  )
}

export default StockList
