import { useEffect, useState } from 'react'

import {
  Box,
  Checkbox,
  FormControl,
  Grid,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import { BarChart, LineChart } from '@mui/x-charts'

import Card from 'components/card'
import Label from 'components/forms/Label'
import { useAuth } from 'hooks/useAuth'
import SetupStorePrompt from 'pages/dashboard/components/SetupStorePrompt'
import SubTabHeader from 'pages/dashboard/components/SubTabHeader'
import TabTitle from 'pages/dashboard/components/TabTitle'

import HeatMap from '../components/HeatMap'

const products = [
  'All Products',
  'A Portfolio of Small Bets — The Course',
  'Iowa — End Grain Cutting Board',
  'California — End Grain Cutting Board',
  'Texas — End Grain Cutting Board',
  'Montana — End Grain Cutting Board',
]

const mapData = {
  India: 8227,
  USA: 2325,
  'United Arab Emirates': 2382,
  Australia: 5000,
}

const revenueData = {
  '2024-10-01': 1200,
  '2024-10-02': 1350,
  '2024-10-03': 1100,
  '2024-10-04': 1450,
  '2024-10-05': 1250,
  '2024-10-06': 1600,
  '2024-10-07': 1750,
  '2024-10-08': 1300,
  '2024-10-09': 1550,
  '2024-10-10': 1400,
}

const AnalyticsPage = (): JSX.Element => {
  const [tableActive, setTableActive] = useState<boolean>(false)
  const { organizationId, organization } = useAuth()
  const [time, setTime] = useState<string>('All Time')
  const [selectedProducts, setSelectedProducts] = useState<string[]>([
    'All Products',
  ])

  const handleChange = (event: SelectChangeEvent): void => {
    setTime(event.target.value)
  }

  const handleProductsChange = (event: SelectChangeEvent<string[]>): void => {
    const {
      target: { value },
    } = event
    setSelectedProducts(typeof value === 'string' ? value.split(',') : value)
  }

  useEffect(() => {
    async function get(): Promise<void> {
      const result = organizationId ? true : false
      if (result) setTableActive(true)
    }
    get()
  }, [organizationId])

  if (organization === null) {
    return <SetupStorePrompt />
  }

  return (
    <div>
      <div className="mb-5">
        {tableActive && organizationId && (
          <div>
            <div className="mb-4 mx-5">
              <TabTitle title="Analytics" />
            </div>
            <div className=" flex justify-between items-center mx-5">
              <SubTabHeader title="Business Metrics" />
              <div className="flex items-center ">
                <FormControl
                  sx={{
                    minWidth: 80,
                    padding: 0,
                    paddingRight: '16px',
                    display: 'none',
                  }}
                  size="small"
                >
                  <Select
                    id="product-select"
                    multiple
                    value={selectedProducts}
                    onChange={handleProductsChange}
                    displayEmpty
                    renderValue={(selected) =>
                      selected.length === 0
                        ? 'Select products...'
                        : selected.length === 1
                          ? selected[0]
                          : `${selected.length} products selected`
                    }
                    input={<OutlinedInput />}
                    sx={{ fontSize: '0.75rem', padding: 0 }}
                  >
                    {products.map((product) => (
                      <MenuItem
                        key={product}
                        value={product}
                        sx={{ padding: '0px 4px 0px 0px' }}
                      >
                        <Checkbox
                          checked={selectedProducts.indexOf(product) > -1}
                          size="small"
                        />
                        <ListItemText
                          primary={product}
                          primaryTypographyProps={{ fontSize: '0.7rem' }}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ minWidth: 100, padding: 0 }} size="small">
                  <Select
                    id="time"
                    value={time}
                    onChange={handleChange}
                    sx={{ fontSize: '0.75rem', padding: 0 }}
                  >
                    <MenuItem value={10} sx={{ fontSize: '0.75rem' }}>
                      Last 30 days
                    </MenuItem>
                    <MenuItem value={20} sx={{ fontSize: '0.75rem' }}>
                      Last month
                    </MenuItem>
                    <MenuItem value={30} sx={{ fontSize: '0.75rem' }}>
                      Last 3 month
                    </MenuItem>
                    <MenuItem value={40} sx={{ fontSize: '0.75rem' }}>
                      This year
                    </MenuItem>
                    <MenuItem value={50} sx={{ fontSize: '0.75rem' }}>
                      Last year
                    </MenuItem>
                    <MenuItem value="All Time" sx={{ fontSize: '0.75rem' }}>
                      All Time
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <Card>
              <Grid container alignItems={'center'} spacing={2}>
                <Grid item xs={12} md={6}>
                  <Label text="Total Number of Orders" />
                  <Typography color={'primary'} fontWeight={600}>
                    700
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Label text="Total Revenue" />
                  <Typography color={'primary'} fontWeight={600}>
                    50000
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Label text="Average Order Value" />
                  <Typography color={'primary'} fontWeight={600}>
                    1000
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Label text="Number of new Customers" />
                  <Typography color={'primary'} fontWeight={600}>
                    35
                  </Typography>
                </Grid>
              </Grid>
            </Card>
            <div className="mx-5">
              <SubTabHeader title="Website Metrics" />
            </div>
            <Card>
              <Grid container alignItems={'center'} spacing={2}>
                <Grid item xs={12} md={6}>
                  <Label text="Unique Visitors" />
                  <Typography color={'primary'} fontWeight={600}>
                    40
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Label text="Total page views" />
                  <Typography color={'primary'} fontWeight={600}>
                    50000
                  </Typography>
                </Grid>
              </Grid>
            </Card>
            <div className="p-5">
              <Grid container spacing={2}>
                {/* Revenue Over Time */}
                <Grid item xs={12} md={6}>
                  <p className="text-lg font-bold">Revenue Over Time</p>
                  <LineChart
                    xAxis={[
                      {
                        scaleType: 'time',
                        data: Object.keys(revenueData).map(
                          (el) => new Date(el)
                        ),
                        tickInterval: Object.keys(revenueData).map(
                          (el) => new Date(el)
                        ),
                        valueFormatter: (date) => {
                          const d = new Date(date)
                          const day = d.getDate()
                          const month = d.toLocaleDateString('en-US', {
                            weekday: 'short',
                          })
                          return `${day}\n${month}`
                        },
                      },
                    ]}
                    yAxis={[
                      {
                        min: 0,
                      },
                    ]}
                    series={[
                      {
                        data: Object.values(revenueData),
                        label: 'Revenue',
                      },
                    ]}
                    height={300}
                  />
                </Grid>

                {/* Customer Engagement: Page Views Over Time */}
                <Grid item xs={12} md={6}>
                  <p className="text-lg font-bold">
                    Customer Engagement: Page Views Over Time
                  </p>
                  <LineChart
                    xAxis={[
                      {
                        scaleType: 'time',
                        data: Object.keys(revenueData).map(
                          (el) => new Date(el)
                        ),
                        tickInterval: Object.keys(revenueData).map(
                          (el) => new Date(el)
                        ),
                        valueFormatter: (date) => {
                          const d = new Date(date)
                          const day = d.getDate()
                          const month = d.toLocaleDateString('en-US', {
                            weekday: 'short',
                          })
                          return `${day}\n${month}`
                        },
                      },
                    ]}
                    yAxis={[
                      {
                        min: 0,
                      },
                    ]}
                    series={[
                      {
                        data: [43, 50, 200, 100, 150, 23, 13, 0, 32],
                        label: 'Views',
                      },
                    ]}
                    height={300}
                  />
                </Grid>

                {/* Referrers (Top Referrers Driving Traffic) */}
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    height="100%"
                  >
                    {/* Heading aligned at the top */}
                    <p className="text-lg font-bold">
                      Top Referrers Driving Traffic
                    </p>
                    {/* BarChart aligned at the bottom */}
                    <Box>
                      <BarChart
                        xAxis={[
                          {
                            scaleType: 'band',
                            data: [
                              'Google',
                              'Instagram',
                              'Twitter',
                              'Whatsapp',
                              'Others',
                            ],
                          },
                        ]}
                        series={[{ data: [70, 50, 30, 10] }]}
                        height={400}
                      />
                    </Box>
                  </Box>
                </Grid>

                {/* Demographics (Global Customer Demographics) */}
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                  <Box height="100%" display="flex" flexDirection="column">
                    {/* Heading aligned at the top */}
                    <p className="text-lg font-bold">
                      Global Customer Demographics
                    </p>
                    {/* HeatMap chart */}
                    <Box>
                      <HeatMap data={mapData} />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default AnalyticsPage
