import React from 'react'

import { Grid, Typography, Button, Box } from '@mui/material'
import { styled } from '@mui/material/styles'

import Amplitude from 'lib/amplitude'

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '2.5rem',
  [theme.breakpoints.down('md')]: {
    fontSize: '1.875rem',
    textAlign: 'center',
  },
}))

const Tagline = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  color: theme.palette.text.secondary,
  fontSize: '1.25rem',
  [theme.breakpoints.down('md')]: {
    fontSize: '1rem',
    textAlign: 'center',
  },
}))

const ButtonGroup = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  display: 'flex',
  gap: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}))

const ImageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(2),
  },
}))

const HeroSection: React.FC = () => {
  const handleRequestEarlyAccessClick = () => {
    const element = document.getElementById('request-early-access')
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
      })
    } else {
      console.error(`Element with id: request-early-access not found.`)
    }
  }

  const handleWatchDemoVideoClick = () => {
    Amplitude.trackEvent('LANDINGPAGE_WATCHDEMOVIDEO_CLICKED', {})
  }
  return (
    <Grid
      container
      spacing={4}
      alignItems="center"
      marginTop={{ xs: 4, sm: 8 }}
    >
      <Grid item xs={12} sm={6} md={5} order={{ xs: 2, sm: 1 }}>
        <Title variant="h2">Build your Online Business with Haulistic</Title>
        <Tagline variant="subtitle1">
          Simplify Selling, Amplify Growth, and Engage Your Customers
        </Tagline>
        <ButtonGroup>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleRequestEarlyAccessClick}
          >
            Request Early Access
          </Button>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            onClick={handleWatchDemoVideoClick}
          >
            Watch Demo Video
          </Button>
        </ButtonGroup>
      </Grid>
      <Grid item xs={12} sm={6} md={7} order={{ xs: 1, sm: 2 }}>
        <ImageContainer>
          <video
            src={
              process.env.REACT_APP_ASSETS_URL +
              '/images/background/coverVideo.mp4'
            }
            className="w-full h-auto rounded-lg"
            autoPlay
            loop
            muted
            playsInline
          />
        </ImageContainer>
      </Grid>
    </Grid>
  )
}

export default HeroSection
