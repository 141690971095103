import { ChangeEvent, FC, useEffect, useState } from 'react'

import { Box, InputAdornment, Typography } from '@mui/material'

import Card from 'components/card'
import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import Amplitude from 'lib/amplitude'
import CustomTextField from 'pages/dashboard/components/CustomTextField'
import SubTabHeader from 'pages/dashboard/components/SubTabHeader'
import { updateProduct } from 'services/products'
import { Product } from 'services/products/index.types'

interface PricingDetailsProps {
  productId: number
  price: number
  stockId: number
  quantity: number
  costPerUnit: number
  onUpdate: (data?: Product) => void
}

const PricingDetails: FC<PricingDetailsProps> = ({
  productId,
  price,
  quantity,
  costPerUnit,
  stockId,
  onUpdate,
}) => {
  const [values, setValues] = useState({ price: '', quantity: '', cost: '' })
  const [error, setError] = useState({ price: '', quantity: '' })
  const [edit, setEdit] = useState(false)
  const { showSnackbar } = useSnackbar()
  const { organizationId } = useAuth()

  useEffect(() => {
    setValues({
      price: price.toString(),
      quantity: quantity.toString(),
      cost: costPerUnit.toString(),
    })
  }, [price, quantity, costPerUnit])

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.name === 'price') {
      setValues((prev) => ({ ...prev, price: e.target.value }))
    } else if (e.target.name === 'quantity') {
      setValues((prev) => ({ ...prev, quantity: e.target.value }))
    } else {
      setValues((prev) => ({ ...prev, cost: e.target.value }))
    }
  }

  const handleEdit = async (): Promise<void> => {
    if (!edit && organizationId) {
      Amplitude.trackEvent('PRODUCT_PRICING_EDIT_CLICKED', {
        productId: productId,
        orgId: organizationId,
      })
    }
    if (edit) {
      if (values.price === '' || values.quantity === '') {
        setError({
          price: values.price === '' ? '*Price is required.' : '',
          quantity: values.quantity === '' ? '*Quantity is required.' : '',
        })
        return
      }

      if (
        +values.price === price &&
        +values.quantity === quantity &&
        +values.cost === costPerUnit
      ) {
        setEdit(false)
        return
      }

      if (organizationId) {
        Amplitude.trackEvent('PRODUCT_PRICING_SAVE_CLICKED', {
          productId: productId,
          orgId: organizationId,
        })
      }

      const updateStock =
        +values.quantity !== quantity || +values.cost !== costPerUnit

      const data = {
        price: +values.price,
        quantity: updateStock ? +values.quantity : undefined,
        cost: updateStock ? +values.cost : undefined,
        stockId: updateStock ? stockId : undefined,
      }

      const res = await updateProduct({
        productId,
        dataUpdate: data,
      })

      if (res.status === 'successful') {
        showSnackbar('Product price updated.', 'success')
        onUpdate(res.data)
      }
    }
    setEdit(!edit)
  }

  return (
    <div className="mb-5">
      <div className="mx-5">
        <SubTabHeader
          title="Pricing & Stock"
          buttonText={edit ? 'Save' : 'Edit'}
          onClick={handleEdit}
        />
      </div>
      <Card>
        <Box display={'flex'} columnGap={2} rowGap={1}>
          <Box flex={1}>
            <Typography
              sx={{
                fontSize: '0.8rem',
                color: 'text.secondary',
                fontWeight: '600',
              }}
            >
              Selling Price
            </Typography>
            {edit ? (
              <CustomTextField
                name="price"
                type="number"
                min={0}
                value={values.price}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                }}
                error={Boolean(error.price)}
                helperText={error.price}
              />
            ) : (
              <Typography sx={{ fontWeight: '700' }}>₹{price}</Typography>
            )}
          </Box>
          <Box flex={1}>
            <Typography
              sx={{
                fontSize: '0.8rem',
                color: 'text.secondary',
                fontWeight: '600',
              }}
            >
              Quantity
            </Typography>
            {edit ? (
              <CustomTextField
                name="quantity"
                type="number"
                min={0}
                value={values.quantity}
                onChange={handleChange}
                error={error.quantity !== ''}
                helperText={error.quantity}
              />
            ) : (
              <Typography sx={{ fontWeight: '700' }}>{quantity}</Typography>
            )}
          </Box>
          <Box flex={1}>
            <Typography
              sx={{
                fontSize: '0.8rem',
                color: 'text.secondary',
                fontWeight: '600',
              }}
            >
              Cost Per Unit
            </Typography>
            {edit ? (
              <CustomTextField
                name="costPerUnit"
                type="number"
                min={0}
                value={values.cost}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                }}
              />
            ) : (
              <Typography sx={{ fontWeight: '700' }}>₹{costPerUnit}</Typography>
            )}
          </Box>
        </Box>
      </Card>
    </div>
  )
}

export default PricingDetails
