import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { Typography, Box } from '@mui/material'

interface NotesEditorProps {
  notes?: string
  onNotesChange: (note: string) => void
}

const NotesEditor = ({ notes, onNotesChange }: NotesEditorProps) => {
  const handleChange = (event: unknown, editor: ClassicEditor) => {
    const data = editor.getData()
    onNotesChange(data)
  }

  return (
    <Box>
      <Typography variant="body2" color="textSecondary">
        Notes are private and won&apos;t be shared with customer
      </Typography>
      <Box mt={2} className="editor-container">
        <CKEditor editor={ClassicEditor} data={notes} onChange={handleChange} />
      </Box>
    </Box>
  )
}

export default NotesEditor
