import { FC, useEffect, useState } from 'react'

import { Button, Typography } from '@mui/material'
import DOMPurify from 'dompurify'

import Card from 'components/card'
import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import Amplitude from 'lib/amplitude'
import SubTabHeader from 'pages/dashboard/components/SubTabHeader'
import { updateProduct } from 'services/products'

import NotesEditor from '../../../components/NotesEditor'

interface NotesDetailsProps {
  productId: number
  notesData?: string
}

const NotesDetails: FC<NotesDetailsProps> = ({ productId, notesData }) => {
  const [notes, setNotes] = useState<string>()
  const [edit, setEdit] = useState(false)
  const { showSnackbar } = useSnackbar()
  const { organizationId } = useAuth()

  useEffect(() => {
    if (notesData) setNotes(notesData)
  }, [notesData])

  const handleNotesChange = (value: string): void => {
    setNotes(value)
  }

  const handleEdit = (): void => {
    if (organizationId) {
      Amplitude.trackEvent('PRODUCT_NOTES_EDIT_CLICKED', {
        productId: productId,
        orgId: organizationId,
      })
    }
    setEdit(true)
  }

  const handleSave = async (): Promise<void> => {
    if (notes?.trim() === notesData) {
      setEdit(false)
      return
    }
    if (organizationId) {
      Amplitude.trackEvent('PRODUCT_NOTES_SAVE_CLICKED', {
        productId: productId,
        orgId: organizationId,
      })
    }

    const result = await updateProduct({
      productId,
      dataUpdate: { notes: notes?.trim() },
    })
    if (result.status === 'successful' && result.data) {
      setNotes(result.data.notes)
      showSnackbar('Notes updated.', 'success')
    }
    setEdit(false)
  }

  const handleCancel = (): void => {
    if (organizationId) {
      Amplitude.trackEvent('PRODUCT_NOTES_CANCEL_CLICKED', {
        productId: productId,
        orgId: organizationId,
      })
    }
    setNotes(notesData)
    setEdit(false)
  }

  return (
    <div>
      <div className="mb-5">
        <div className="mx-5">
          <SubTabHeader
            title="Notes"
            buttonText={edit ? undefined : 'Edit'}
            onClick={handleEdit}
          />
        </div>
        <Card>
          {!edit && (
            <Typography
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(notes || 'No notes available'),
              }}
            />
          )}
          {edit && (
            <div>
              <NotesEditor notes={notes} onNotesChange={handleNotesChange} />
              <div className="mt-4">
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ textTransform: 'none', mr: 2 }}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  sx={{ textTransform: 'none' }}
                  onClick={handleSave}
                >
                  Save
                </Button>
              </div>
            </div>
          )}
        </Card>
      </div>
    </div>
  )
}

export default NotesDetails
